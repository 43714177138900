import React from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { useEditorSettingStore } from "@src/store/editor-setting-store";
import {
  AsahiRuleCategory,
  AsahiRuleCategoryJa,
  AsahiRuleSubCategory,
  AsahiRuleSubCategoryJa,
} from "@src/types/asahi-rule-category";
import clsx from "clsx";

export function AsahiRuleCategorySettings() {
  const isAsahiRuleInappropriateExpressionsVisible = useEditorSettingStore(
    (state) => state.isAsahiRuleInappropriateExpressionsVisible
  );
  const isAsahiRuleNonJoyoKanjiVisible = useEditorSettingStore((state) => state.isAsahiRuleNonJoyoKanjiVisible);
  const isAsahiRuleNonJoyoReadingVisible = useEditorSettingStore((state) => state.isAsahiRuleNonJoyoReadingVisible);
  const isAsahiRuleCharacterFormVisible = useEditorSettingStore((state) => state.isAsahiRuleCharacterFormVisible);
  const isAsahiRuleMixedScriptVisible = useEditorSettingStore((state) => state.isAsahiRuleMixedScriptVisible);
  const isAsahiRuleKanjiAllowedVisible = useEditorSettingStore((state) => state.isAsahiRuleKanjiAllowedVisible);
  const isAsahiRuleNumericFormatVisible = useEditorSettingStore((state) => state.isAsahiRuleNumericFormatVisible);
  const isAsahiRuleMisuseVisible = useEditorSettingStore((state) => state.isAsahiRuleMisuseVisible);
  const isAsahiRuleIdiomaticExpressionVisible = useEditorSettingStore(
    (state) => state.isAsahiRuleIdiomaticExpressionVisible
  );
  const isAsahiRuleCommonNameVisible = useEditorSettingStore((state) => state.isAsahiRuleCommonNameVisible);
  const isAsahiRuleKatakanaCautionVisible = useEditorSettingStore((state) => state.isAsahiRuleKatakanaCautionVisible);
  const isAsahiRuleKanaCautionVisible = useEditorSettingStore((state) => state.isAsahiRuleKanaCautionVisible);
  const isAsahiRuleCharacterTypeCautionVisible = useEditorSettingStore(
    (state) => state.isAsahiRuleCharacterTypeCautionVisible
  );
  const isAsahiRuleHomonymVisible = useEditorSettingStore((state) => state.isAsahiRuleHomonymVisible);
  const isAsahiRuleHonorificsCautionVisible = useEditorSettingStore(
    (state) => state.isAsahiRuleHonorificsCautionVisible
  );
  const isAsahiRuleKanaReadingVisible = useEditorSettingStore((state) => state.isAsahiRuleKanaReadingVisible);
  const isAsahiRuleInputConversionErrorVisible = useEditorSettingStore(
    (state) => state.isAsahiRuleInputConversionErrorVisible
  );
  const isAsahiRuleRedundantExpressionVisible = useEditorSettingStore(
    (state) => state.isAsahiRuleRedundantExpressionVisible
  );
  const isAsahiRuleOmittedCharacterVisible = useEditorSettingStore((state) => state.isAsahiRuleOmittedCharacterVisible);
  const isAsahiRulePlaceNameCautionVisible = useEditorSettingStore((state) => state.isAsahiRulePlaceNameCautionVisible);
  const isAsahiRuleProperNounCautionVisible = useEditorSettingStore(
    (state) => state.isAsahiRuleProperNounCautionVisible
  );
  const isAsahiRuleTechnicalTermVisible = useEditorSettingStore((state) => state.isAsahiRuleTechnicalTermVisible);

  const toggleIsAsahiRuleInappropriateExpressionsVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleInappropriateExpressionsVisible
  );
  const toggleIsAsahiRuleNonJoyoKanjiVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleNonJoyoKanjiVisible
  );
  const toggleIsAsahiRuleNonJoyoReadingVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleNonJoyoReadingVisible
  );
  const toggleIsAsahiRuleCharacterFormVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleCharacterFormVisible
  );
  const toggleIsAsahiRuleMixedScriptVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleMixedScriptVisible
  );
  const toggleIsAsahiRuleKanjiAllowedVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleKanjiAllowedVisible
  );
  const toggleIsAsahiRuleNumericFormatVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleNumericFormatVisible
  );
  const toggleIsAsahiRuleMisuseVisible = useEditorSettingStore((state) => state.toggleIsAsahiRuleMisuseVisible);
  const toggleIsAsahiRuleIdiomaticExpressionVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleIdiomaticExpressionVisible
  );
  const toggleIsAsahiRuleCommonNameVisible = useEditorSettingStore((state) => state.toggleIsAsahiRuleCommonNameVisible);
  const toggleIsAsahiRuleKatakanaCautionVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleKatakanaCautionVisible
  );
  const toggleIsAsahiRuleKanaCautionVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleKanaCautionVisible
  );
  const toggleIsAsahiRuleCharacterTypeCautionVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleCharacterTypeCautionVisible
  );
  const toggleIsAsahiRuleHomonymVisible = useEditorSettingStore((state) => state.toggleIsAsahiRuleHomonymVisible);
  const toggleIsAsahiRuleHonorificsCautionVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleHonorificsCautionVisible
  );
  const toggleIsAsahiRuleKanaReadingVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleKanaReadingVisible
  );
  const toggleIsAsahiRuleInputConversionErrorVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleInputConversionErrorVisible
  );
  const toggleIsAsahiRuleRedundantExpressionVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleRedundantExpressionVisible
  );
  const toggleIsAsahiRuleOmittedCharacterVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleOmittedCharacterVisible
  );
  const toggleIsAsahiRulePlaceNameCautionVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRulePlaceNameCautionVisible
  );
  const toggleIsAsahiRuleProperNounCautionVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleProperNounCautionVisible
  );
  const toggleIsAsahiRuleTechnicalTermVisible = useEditorSettingStore(
    (state) => state.toggleIsAsahiRuleTechnicalTermVisible
  );

  // 右記は不必要と考え使わない。「"asahiRule": "朝日特有ルール","verticalWriting": "縦書き"」
  const asahiRuleSubCategoryGroups: {
    type: AsahiRuleCategory;
    label: AsahiRuleCategoryJa;
    categories: {
      type: AsahiRuleSubCategory;
      label: AsahiRuleSubCategoryJa;
      example: string;
      description: string;
      checked: boolean;
      onChange: () => void;
    }[];
  }[] = [
    // カテゴリー「朝日特有ルール、縦書き」については不要と判断し、レスポンスには含まれるが、UI上は表示しない
    {
      type: AsahiRuleCategory.EXPRESSION,
      label: AsahiRuleCategoryJa.EXPRESSION,
      categories: [
        {
          type: AsahiRuleSubCategory.INAPPROPRIATE_EXPRESSIONS,
          label: AsahiRuleSubCategoryJa.INAPPROPRIATE_EXPRESSIONS,
          example: "差別的なニュアンスを含む表現を指摘",
          description:
            "特定の個人や集団に対して配慮を欠く、差別的なニュアンスを含む表現を指摘します。\n例：盲目的、外人、看護婦",
          checked: isAsahiRuleInappropriateExpressionsVisible,
          onChange: toggleIsAsahiRuleInappropriateExpressionsVisible,
        },
        {
          type: AsahiRuleSubCategory.NON_JOYO_KANJI,
          label: AsahiRuleSubCategoryJa.NON_JOYO_KANJI,
          example: "例：上綴じ→上とじ、挨拶→あいさつ",
          description:
            "常用漢字に含まれない漢字（表外字）を指摘します。一部、朝日新聞社独自に利用を避けている漢字も含まれます。",
          checked: isAsahiRuleNonJoyoKanjiVisible,
          onChange: toggleIsAsahiRuleNonJoyoKanjiVisible,
        },
        {
          type: AsahiRuleSubCategory.NON_JOYO_READING,
          label: AsahiRuleSubCategoryJa.NON_JOYO_READING,
          example: "例：御墨付き→お墨付き、奇しくも→くしくも",
          description:
            "常用漢字に含まれない音読みや訓読みについて指摘します。一部、朝日新聞社独自に利用を避けている読みも含まれます。",
          checked: isAsahiRuleNonJoyoReadingVisible,
          onChange: toggleIsAsahiRuleNonJoyoReadingVisible,
        },
        {
          type: AsahiRuleSubCategory.CHARACTER_FORM,
          label: AsahiRuleSubCategoryJa.CHARACTER_FORM,
          example: "例：勵みになる→励みになる、乾麵→乾麺",
          description: "異体字を指摘します。",
          checked: isAsahiRuleCharacterFormVisible,
          onChange: toggleIsAsahiRuleCharacterFormVisible,
        },
        {
          type: AsahiRuleSubCategory.MIXED_SCRIPT,
          label: AsahiRuleSubCategoryJa.MIXED_SCRIPT,
          example: "例：形がい化→形骸化、おく病→憶病",
          description: "一部が漢字やひらがな、カタカナで書かれている「交ぜ書き」を指摘します。",
          checked: isAsahiRuleMixedScriptVisible,
          onChange: toggleIsAsahiRuleMixedScriptVisible,
        },
        {
          type: AsahiRuleSubCategory.KANJI_ALLOWED,
          label: AsahiRuleSubCategoryJa.KANJI_ALLOWED,
          example: "例：ざん新→斬新、食欲おう盛→食欲旺盛",
          description: "常用漢字の観点において、漢字を使用しても問題ない熟語などを指摘します。",
          checked: isAsahiRuleKanjiAllowedVisible,
          onChange: toggleIsAsahiRuleKanjiAllowedVisible,
        },
        {
          type: AsahiRuleSubCategory.NUMERIC_FORMAT,
          label: AsahiRuleSubCategoryJa.NUMERIC_FORMAT,
          example: "例：もう１度→もう一度、第一子→第１子",
          description: "漢数字や洋数字の使い分けや数字書式の統一に関する指摘です。",
          checked: isAsahiRuleNumericFormatVisible,
          onChange: toggleIsAsahiRuleNumericFormatVisible,
        },
      ],
    },
    {
      type: AsahiRuleCategory.MISUSE,
      label: AsahiRuleCategoryJa.MISUSE,
      categories: [
        {
          type: AsahiRuleSubCategory.MISUSE,
          label: AsahiRuleSubCategoryJa.MISUSE,
          example: "例：汚名を挽回する→汚名を返上する",
          description: "不自然な表現や意味が逆転している表現を指摘します。\n例：ハラスメント研修→ハラスメント防止研修",
          checked: isAsahiRuleMisuseVisible,
          onChange: toggleIsAsahiRuleMisuseVisible,
        },
        {
          type: AsahiRuleSubCategory.IDIOMATIC_EXPRESSION,
          label: AsahiRuleSubCategoryJa.IDIOMATIC_EXPRESSION,
          example: "例：二の舞いを繰り返す→二の舞いを演じる",
          description: "慣用表現の誤用に関する指摘です。\n例：対岸の火災→対岸の火事",
          checked: isAsahiRuleIdiomaticExpressionVisible,
          onChange: toggleIsAsahiRuleIdiomaticExpressionVisible,
        },
        {
          type: AsahiRuleSubCategory.COMMON_NAME,
          label: AsahiRuleSubCategoryJa.COMMON_NAME,
          example: "例：東日本旅客鉄道→ＪＲ東日本",
          description: "正式名称ではなく通称が一般認知されているケースを指摘します。",
          checked: isAsahiRuleCommonNameVisible,
          onChange: toggleIsAsahiRuleCommonNameVisible,
        },
      ],
    },
    {
      type: AsahiRuleCategory.COUTION,
      label: AsahiRuleCategoryJa.COUTION,
      categories: [
        {
          type: AsahiRuleSubCategory.KATAKANA_CAUTION,
          label: AsahiRuleSubCategoryJa.KATAKANA_CAUTION,
          example: "例：キャリアー採用→キャリア採用",
          description:
            "カタカナ語における不要な音引きや不適切な表記を指摘します。\n例：シチュエイション→シチュエーション",
          checked: isAsahiRuleKatakanaCautionVisible,
          onChange: toggleIsAsahiRuleKatakanaCautionVisible,
        },
        {
          type: AsahiRuleSubCategory.KANA_CAUTION,
          label: AsahiRuleSubCategoryJa.KANA_CAUTION,
          example: "例：着払→着払い、引渡時期→引き渡し時期",
          description: "仮名遣いや送り仮名に関する誤りを指摘します。",
          checked: isAsahiRuleKanaCautionVisible,
          onChange: toggleIsAsahiRuleKanaCautionVisible,
        },
        {
          type: AsahiRuleSubCategory.CHARACTER_TYPE_CAUTION,
          label: AsahiRuleSubCategoryJa.CHARACTER_TYPE_CAUTION,
          example: "例：勿論です→もちろんです、何月頃→何月ごろ",
          description: "仮名遣いや送り仮名に関する誤りを指摘します。",
          checked: isAsahiRuleCharacterTypeCautionVisible,
          onChange: toggleIsAsahiRuleCharacterTypeCautionVisible,
        },
        {
          type: AsahiRuleSubCategory.HOMONYM,
          label: AsahiRuleSubCategoryJa.HOMONYM,
          example: "例：身振るい→身震い、同時平行→同時並行",
          description: "同じ発音で異なる漢字や意味を持つ表現に関する誤用を指摘します。",
          checked: isAsahiRuleHomonymVisible,
          onChange: toggleIsAsahiRuleHomonymVisible,
        },
        {
          type: AsahiRuleSubCategory.HONORIFICS_CAUTION,
          label: AsahiRuleSubCategoryJa.HONORIFICS_CAUTION,
          example: "例：必ずご持参ください→必ずお持ちください",
          description: "敬語表現の誤用を指摘します。\n例：ご紹介させていただき→二重敬語の可能性",
          checked: isAsahiRuleHonorificsCautionVisible,
          onChange: toggleIsAsahiRuleHonorificsCautionVisible,
        },
      ],
    },
    {
      type: AsahiRuleCategory.INPUT_ERRORS,
      label: AsahiRuleCategoryJa.INPUT_ERRORS,
      categories: [
        {
          type: AsahiRuleSubCategory.KANA_READING,
          label: AsahiRuleSubCategoryJa.KANA_READING,
          example: "例：脆弱性→脆弱（ぜいじゃく）性",
          description: "ふりがなを振ることを推奨する言葉を指摘します。",
          checked: isAsahiRuleKanaReadingVisible,
          onChange: toggleIsAsahiRuleKanaReadingVisible,
        },
        {
          type: AsahiRuleSubCategory.INPUT_CONVERSION_ERROR,
          label: AsahiRuleSubCategoryJa.INPUT_CONVERSION_ERROR,
          example: "例：キャシュレス→キャッシュレス",
          description: "漢字の変換や入力ミスにより生じる誤りを指摘します。\n例：自縄作用→自浄作用",
          checked: isAsahiRuleInputConversionErrorVisible,
          onChange: toggleIsAsahiRuleInputConversionErrorVisible,
        },
        {
          type: AsahiRuleSubCategory.REDUNDANT_EXPRESSION,
          label: AsahiRuleSubCategoryJa.REDUNDANT_EXPRESSION,
          example: "例：炎天下のなか→炎天下、委員員長→委員長",
          description: "文章中で同じ語や助詞が重複しているケースを指摘します。\n例：明日までまで→明日まで",
          checked: isAsahiRuleRedundantExpressionVisible,
          onChange: toggleIsAsahiRuleRedundantExpressionVisible,
        },
        {
          type: AsahiRuleSubCategory.OMITTED_CHARACTER,
          label: AsahiRuleSubCategoryJa.OMITTED_CHARACTER,
          example: "例：来度予算→来年度予算",
          description: "文章中で一部の文字が欠落しているケースを指摘します。\n例：これついては→これについては",
          checked: isAsahiRuleOmittedCharacterVisible,
          onChange: toggleIsAsahiRuleOmittedCharacterVisible,
        },
      ],
    },
    {
      type: AsahiRuleCategory.PROPER_NOUNS,
      label: AsahiRuleCategoryJa.PROPER_NOUNS,
      categories: [
        {
          type: AsahiRuleSubCategory.PLACE_NAME_CAUTION,
          label: AsahiRuleSubCategoryJa.PLACE_NAME_CAUTION,
          example: "例：横浜市中央区（存在しない区）\n井予市→伊予市（変換ミス）",
          description: "日本国内および海外の地名に関する誤記を指摘します。",
          checked: isAsahiRulePlaceNameCautionVisible,
          onChange: toggleIsAsahiRulePlaceNameCautionVisible,
        },
        {
          type: AsahiRuleSubCategory.PROPER_NOUN_CAUTION,
          label: AsahiRuleSubCategoryJa.PROPER_NOUN_CAUTION,
          example: "例：テトラポッド→消波ブロック",
          description: "特定の固有名詞の表記ミスを指摘します。使ってしまいがちな商標も指摘します。",
          checked: isAsahiRuleProperNounCautionVisible,
          onChange: toggleIsAsahiRuleProperNounCautionVisible,
        },
        {
          type: AsahiRuleSubCategory.TECHNICAL_TERM,
          label: AsahiRuleSubCategoryJa.TECHNICAL_TERM,
          example: "例：エンゲル計数→エンゲル係数",
          description: "特定の分野で使用される用語の誤りを指摘します。\n例：開票所立会人→開票立会人",
          checked: isAsahiRuleTechnicalTermVisible,
          onChange: toggleIsAsahiRuleTechnicalTermVisible,
        },
      ],
    },
  ];

  return (
    <div className="flex h-full flex-col ">
      <div className="flex w-full items-center justify-center gap-2">
        <div className="font-bold text-complementary-500 text-sm">【ルール辞書】 校正カテゴリー設定</div>
      </div>
      <TabGroup>
        <TabList className="mt-2 space-x-2 space-y-2">
          {asahiRuleSubCategoryGroups.map(({ type, label }) => (
            <Tab
              key={type}
              className={({ selected }) =>
                clsx(
                  "px-1.5 py-1 text-sm",
                  "rounded-lg border border-complementary-500",
                  selected
                    ? "text-white bg-complementary-500"
                    : "hover:bg-neutral-100 hover:text-neutral-500 text-complementary-500"
                )
              }
              style={{ marginLeft: type === AsahiRuleCategory.PROPER_NOUNS && "0px" }}
            >
              {label}
            </Tab>
          ))}
        </TabList>
        <TabPanels className="px-3 py-8">
          {asahiRuleSubCategoryGroups.map(({ categories, type }) => {
            return (
              <TabPanel key={type} className="grid grid-cols-1 gap-5 md:grid-cols-[auto_auto]">
                {categories.map(({ type, label, example, checked, onChange }) => {
                  return (
                    <div key={type} className="flex items-center gap-3 accent-complementary-500">
                      <input id={type} type="checkbox" checked={checked} onChange={onChange} />
                      <label className="group relative" htmlFor={type}>
                        <p className="text-sm font-bold hover:opacity-70">{label}</p>
                        <p className="whitespace-pre-wrap text-xs">{example}</p>
                      </label>
                    </div>
                  );
                })}
              </TabPanel>
            );
          })}
        </TabPanels>
      </TabGroup>
    </div>
  );
}
