export const TextlintCategory = {
  // 半角カナ
  NO_HANKAKU_KANA: "NO_HANKAKU_KANA",
  // 助詞の連続
  NO_DOUBLED_JOSHI: "NO_DOUBLED_JOSHI",
  // 二重否定
  NO_DOUBLE_NEGATIVE: "NO_DOUBLE_NEGATIVE",
  // ですます・である
  NO_MIX_DEARU_DESUMASU: "NO_MIX_DEARU_DESUMASU",
  // 文の長さ
  SENTENCE_LENGTH: "SENTENCE_LENGTH",
  // かっこの対応
  NO_UNMATCHED_PAIR: "NO_UNMATCHED_PAIR",
  // 「が」の連続
  NO_DOUBLED_GA: "NO_DOUBLED_GA",
  // NFD（゛゜）
  NO_NFD: "NO_NFD",
  // 〜たり〜たり
  PREFER_TARI_TARI: "PREFER_TARI_TARI",
  // 冗長な表現
  NO_REDUNDANT_EXPRESSION: "NO_REDUNDANT_EXPRESSION",
  // 同一単語の連続
  NO_SUCCESSIVE_WORD: "NO_SUCCESSIVE_WORD",
  // 読点の数
  MAX_TEN: "MAX_TEN",
  // 形式名詞のひらがな表記
  HIRAGANA_KEISHIKIMEISHI: "HIRAGANA_KEISHIKIMEISHI",
  // 副詞のひらがな表記
  HIRAGANA_FUKUSHI: "HIRAGANA_FUKUSHI",
  // 補助動詞のひらがな表記
  HIRAGANA_HOJODOUSHI: "HIRAGANA_HOJODOUSHI",
  // 年月日と曜日の不一致
  DATE_WEEKDAY_MISMATCH: "DATE_WEEKDAY_MISMATCH",
  // 句読点
  NO_MIXED_PERIOD: "NO_MIXED_PERIOD",
  // 全角数字
  NO_ZENKAKU_NUMBERS: "NO_ZENKAKU_NUMBERS",
  // 「ら」抜き言葉
  NO_DROPPING_RA: "NO_DROPPING_RA",
  // 「い」抜き言葉
  NO_DROPPING_I: "NO_DROPPING_I",
  // 「さ」入れ言葉
  NO_INSERTING_SA: "NO_INSERTING_SA",
  // 表記揺れチェック
  NO_SYNONYMS: "NO_SYNONYMS",
  // 不自然なアルファベット
  UNNATURAL_ALPHABET: "UNNATURAL_ALPHABET",
  // 同一接続詞の連続
  NO_DOUBLED_CONJUNCTION: "NO_DOUBLED_CONJUNCTION",
} as const;

export const TextlintCategoryJa = {
  NO_HANKAKU_KANA: "半角カナ",
  NO_DOUBLED_JOSHI: "助詞の連続",
  NO_DOUBLE_NEGATIVE: "二重否定",
  NO_MIX_DEARU_DESUMASU: "ですます・である",
  SENTENCE_LENGTH: "文の長さ",
  NO_UNMATCHED_PAIR: "かっこの対応",
  NO_DOUBLED_GA: "「が」の連続",
  NO_NFD: "NFD（゛゜）",
  PREFER_TARI_TARI: "〜たり〜たり",
  NO_REDUNDANT_EXPRESSION: "冗長な表現",
  NO_SUCCESSIVE_WORD: "同一単語の連続",
  MAX_TEN: "読点の数",
  HIRAGANA_KEISHIKIMEISHI: "形式名詞のひらがな表記",
  HIRAGANA_FUKUSHI: "副詞のひらがな表記",
  HIRAGANA_HOJODOUSHI: "補助動詞のひらがな表記",
  DATE_WEEKDAY_MISMATCH: "年月日と曜日の不一致",
  NO_MIXED_PERIOD: "句読点",
  NO_ZENKAKU_NUMBERS: "全角数字",
  NO_DROPPING_RA: "「ら」抜き言葉",
  NO_DROPPING_I: "「い」抜き言葉",
  NO_INSERTING_SA: "「さ」入れ言葉",
  NO_SYNONYMS: "表記揺れチェック",
  UNNATURAL_ALPHABET: "不自然なアルファベット",
  NO_DOUBLED_CONJUNCTION: "同一接続詞の連続",
} as const;

export type TextlintCategory = (typeof TextlintCategory)[keyof typeof TextlintCategory];

export function getTextlintCategoryJa(category: string): string {
  const textlintCategoryKey = Object.keys(TextlintCategory).find(
    (key) => TextlintCategory[key as keyof typeof TextlintCategory] === category
  );

  if (textlintCategoryKey) {
    return TextlintCategoryJa[textlintCategoryKey as keyof typeof TextlintCategoryJa];
  }

  return category;
}
